import * as React from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import s from './ViewMore.scss';

function ViewMoreComponent({globals: {navigate, product}}: ProvidedGlobalProps) {
  return (
    <a data-hook={'quick-view-more'} className={s.root} onClick={() => navigate(product.urlPart, true)}>
      View Product Details
    </a>
  );
}

export const ViewMore = withGlobalProps(ViewMoreComponent);
