import * as React from 'react';
import {ProvidedGlobalProps, withDirection, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';
import s from './ProductPageButtonsContainer.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {AddToCartButton} from './AddToCartButton/AddToCartButton';
import {Mode, WishlistButton} from './WishlistButton/WishlistButton';
import {isFunction} from 'util';
import {BuyNowButton} from './BuyNowButton/BuyNowButton';
import {inStock} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ShowOnDesktopOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnDesktopOnly/ShowOnDesktopOnly';
import {SubscribeButton} from './SubscribeButton/SubscribeButton';

export interface ProductPageButtonsContainerProps extends ProvidedGlobalProps, IProvidedTranslationProps {}

export enum DataHook {
  secondRow = 'buy-now-button-container',
  wishlistContainer = 'wishlist-container',
}

@withGlobalProps
@withTranslations('globals.texts')
@withDirection
export class ProductPageButtonsContainer extends React.Component<ProductPageButtonsContainerProps> {
  private readonly handleAddToCart = async () => {
    const {handleAddToCart} = this.props.globals;

    if (isFunction(handleAddToCart)) {
      await handleAddToCart();
    }
  };

  private readonly shouldShowSecondRow = (disabled: boolean): boolean => {
    const {
      globals: {shouldShowAddToCartButton, shouldShowBuyNowButton, shouldShowSubscribeButton},
    } = this.props;
    return shouldShowAddToCartButton && shouldShowBuyNowButton && !disabled && !shouldShowSubscribeButton;
  };

  public render() {
    const {
      globals: {
        selectedVariant,
        product,
        shouldShowWishlistButton,
        shouldShowAddToCartButton,
        shouldShowBuyNowButton,
        shouldShowSubscribeButton,
      },
    } = this.props;
    const buttonContainerClass = classNames(s.productOptionsContainer, {
      [s.addToCartButtonContainer]: shouldShowWishlistButton,
    });
    const wishlistButtonContainer = classNames({
      [s.wishlistContainer]: !shouldShowAddToCartButton && !shouldShowBuyNowButton && !shouldShowSubscribeButton,
    });

    const secondRowClasses = classNames(s.productOptionsContainer, s.secondaryButton);
    const productInStock = inStock(product, selectedVariant);
    const {price} = selectedVariant || product;
    const disabled = !productInStock || price <= 0;

    return (
      <>
        <Cell className={buttonContainerClass}>
          {shouldShowSubscribeButton && <SubscribeButton productInStock={productInStock} />}

          {!shouldShowSubscribeButton && shouldShowAddToCartButton && (
            <AddToCartButton productInStock={productInStock} handleAddToCart={this.handleAddToCart} />
          )}
          {!shouldShowSubscribeButton && shouldShowBuyNowButton && !shouldShowAddToCartButton && (
            <BuyNowButton productInStock={productInStock} />
          )}
          {shouldShowWishlistButton && (
            <ShowOnDesktopOnly className={wishlistButtonContainer} data-hook={DataHook.wishlistContainer}>
              <WishlistButton mode={Mode.INLINE} />
            </ShowOnDesktopOnly>
          )}
        </Cell>
        {this.shouldShowSecondRow(disabled) && (
          <Cell data-hook={DataHook.secondRow} className={secondRowClasses}>
            {<BuyNowButton productInStock={productInStock} />}
          </Cell>
        )}
      </>
    );
  }
}
