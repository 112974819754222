import React from 'react';
import {Cell} from '../Cell/Cell';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {withGlobalProps} from '../../../providers/globalPropsProvider';
import s from './ResponsiveLayout.scss';
import {ResponsiveGallery} from './ResponsiveGallery/ResponsiveGallery';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {Navigation} from '../../Navigation/Navigation';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ShowOnMobileOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnMobileOnly/ShowOnMobileOnly';
import {Mode, WishlistButton} from '../../ProductPageButtonsContainer/WishlistButton/WishlistButton';

@withGlobalProps
export class ResponsiveLayout extends React.Component<LayoutComponentProps> {
  private renderLeftCol() {
    const {
      product,
      settings,
      globals: {shouldShowWishlistButton},
    } = this.props;

    return (
      <section>
        <Cell className={s.gallery}>
          <ResponsiveGallery media={product.media} imageMode={settings.imageMode} imageRatio={settings.imageRatioId} />
          {shouldShowWishlistButton && (
            <ShowOnMobileOnly>
              <WishlistButton mode={Mode.FLOATING} />
            </ShowOnMobileOnly>
          )}
        </Cell>
      </section>
    );
  }

  private renderRightCol() {
    const {product, settings} = this.props;

    return (
      <section>
        <Cell className={s.name}>
          <ProductName name={product.name} />
        </Cell>

        {settings.shouldShowPrice && (
          <Cell>
            <ProductPriceContainer className={s.price} />
          </Cell>
        )}
        {settings.shouldShowSku && (
          <Cell className={s.sku}>
            <ProductSku />
          </Cell>
        )}

        <Cell>
          <DetailsPlaceholder />
        </Cell>

        <Cell className={s.options}>
          <ProductOptions shouldShowQuantity={settings.shouldShowQuantity} />
          <ProductPageButtonsContainer />
        </Cell>

        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>

        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}

        {settings.shouldShowSocialNetwork && (
          <Cell className={s.social}>
            <ProductSocial />
          </Cell>
        )}
      </section>
    );
  }

  public render() {
    const {shouldShowNavigation} = this.props.settings;

    return (
      <article className={s.container}>
        {shouldShowNavigation && <Navigation className={s.navigation} renderBreadcrumbs={false} />}
        <main className={s.main}>
          {this.renderLeftCol()}
          {this.renderRightCol()}
        </main>
        <Cell>
          <BottomPlaceholder />
        </Cell>
      </article>
    );
  }
}
